/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import SabineNeurologyJPG from '@assets/images/sabine-neurology.jpg';
import OchsNeurologyJPG from '@assets/images/ochs-neurology.jpg';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainer,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/neurologie';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/neurology';

const NeurologyPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('NeuroContent1') || tEn('NeuroContent1'),
            }}
          />
          <FlexContainer>
            <ImageContainer>
              <Image
                src={SabineNeurologyJPG}
                alt="Sabine Neurologie"
                width={350}
              />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('NeuroContent2') || tEn('NeuroContent2'),
                }}
              />
            </List>
          </FlexContainer>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('NeuroContent3') || tEn('NeuroContent3'),
                }}
              />
            </List>
            <ImageContainer>
              <Ochs>
                <Image src={OchsNeurologyJPG} alt="Ochs" width={350} />
              </Ochs>
            </ImageContainer>
          </FlexContainer>
        </ContainerSmall>
      </Container>
    </>
  );
};

const Ochs = styled.div`
  @media (min-width: 800px) {
    margin-left: 2rem;
  }
`;

export default NeurologyPage;
