import React from 'react';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import NeurologyMain from '~/components/organisms/NeurologyMain/NeurologyMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const NeurologyPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroRoomJPG}
        mobileHero={NeuroRoomMobileJPG}
        altHero="Neurologie Praxisraum"
        enLink="/en/neurology/"
        deLink="/neurologie/"
      >
        <NeurologyMain />
      </HeaderFooterLayout>
    </>
  );
};

export default NeurologyPage;
